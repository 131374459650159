audio[controls] {
  max-width: 100%;
  min-height: 60px;
}

.store-links {
  max-width: 100%;
  width: 300px;
  a {
    display: inline-block;
    width: 50%;
    &.apple {
      padding-right: 5px;
    }
    &.spotify {
      padding-left: 5px;
    }
  }
  img {
    max-height: 100%;
    max-width: 100%;
  }
}

.text-center .store-links {
  margin: auto;
}
