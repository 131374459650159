html, body {
  margin: 0;
  padding: 0;
  height: 100%;
}
button {
  background: none;
  border: none;
  color: $link-color;
  padding: 0;

  @include hover-focus-active() {
    color: $link-hover-color;
    text-decoration: $link-hover-decoration;
  }
}
em {
  font-size: 24px;
}
h1, h2, h3, h4, h5, h6 {
  margin-top: 0;
}

.badge-themed {
  @include button-variant($theme, $theme, $theme);
}
.btn-themed {
  @include button-variant($theme, $theme, $theme);
}
.btn-icon {
  color: $link-color;
  padding: 0;
  @include hover-focus-active() {
    color: $link-hover-color;
  }
}

.custom-html {
  h1, h2, h3, h4, h5, h6 {
    text-align: center;
  }

  img, video {
    display: block;
    margin: auto;
    max-width: 100%;
    text-align: center;
  }
}

.notifications {
  left: 50%;
  position: fixed;
  text-align: center;
  top: 5px;
  transform: translate(-50%, 0%);
  width: 350px;
  z-index: 15;
}

.preserve-whitespace { white-space: pre-wrap; }
