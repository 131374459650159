@import "~trix/dist/trix";

.content-editor {
  margin-left: auto;
  margin-right: auto;
  max-width: 100%;
  width: map-get($grid-breakpoints, md);
}

.content-editor-wrapper {
  @include media-breakpoint-up(md) {
    left: 50%;
    margin-left: -50vw;
    margin-right: -50vw;
    position: relative;
    right: 50%;
    width: 100vw;
  }
}

.trix-content {
  img, video { max-width: 100%; }

  :not(.attachment-gallery) {
    > action-text-attachment,
    > .attachment {
      display: block;
      margin: 20px 0;
      text-align: center;
    }
  }

  .attachment__caption { display: none; }
  .attachment-gallery {
    margin: 20px 0;

    .attachment {
      max-width: 100% !important;
      padding: 0 0.5em;
    }

    &.attachment-gallery--2 {
      > action-text-attachment,
      > .attachment {
        flex-basis: 50%;
      }
    }

    &.attachment-gallery--3 {
      > action-text-attachment,
      > .attachment {
        flex: 1 0 33%;
      }
    }

    &.attachment-gallery--4 {
      > action-text-attachment,
      > .attachment {
        flex-basis: 25%;
      }
    }
  }

  h1, .h1 { @include font-size($h1-font-size); }
  h2, .h2 { @include font-size($h2-font-size); }
  h3, .h3 { @include font-size($h3-font-size); }
  h4, .h4 { @include font-size($h4-font-size); }
  h5, .h5 { @include font-size($h5-font-size); }
  h6, .h6 { @include font-size($h6-font-size); }
}

trix-editor {
  margin-top: 10px;
}

trix-toolbar {
  background: white;
  position: sticky;
  top: 0;
  z-index: 1;

  .trix-button-group { margin-bottom: 0; }
}
