$black: #000000;
$blue: #4D90FE;
$green: #00CC99;
$white: #FFFFFF;

$body-color: $black;
$theme: $green;
$brand-primary: $blue;
$brand-success: $green;
$link-color: $theme;
$link-hover-color: $blue;
$state-success-text: darken($brand-success, 15%);
$state-success-bg: lighten($brand-success, 36%);
$toast-background-color: $white;

$font-family-sans-serif: "Raleway";
